import { Link } from "react-router-dom";

// const newsTitle = "Want Us To Email You About Special Offers And Updates?";
const siteTitle = "Site Map";
const useTitle = "Useful Links";
const socialTitle = "Social Contact";
const supportTitle = "Get Aplication";

const siteList = [
  {
    text: "Feedback",
    link: "https://t.me/+uHRKBSoCKPQ4OWQ9",
  },
  {
    text: "Support Forums",
    link: "https://t.me/+uHRKBSoCKPQ4OWQ9",
  },
];

const useList = [
  {
    text: "Help Center",
    link: "https://t.me/+uHRKBSoCKPQ4OWQ9",
  },
  {
    text: "Contact Support",
    link: "https://api.whatsapp.com/send?phone=6281246462453",
  },
];

const socialList = [
  {
    text: "Telegram",
    link: "https://t.me/+uHRKBSoCKPQ4OWQ9",
  },
  {
    text: "Instagram",
    link: "https://www.instagram.com/pengenbisa/",
  },
  {
    text: "Email",
    link: "https://mail.google.com/mail/?view=cm&fs=1&to=hallopengenbisa@gmail.com",
  },
  {
    text: "Facebook",
    link: "https://web.facebook.com/Pengen-Bisa-109628460721763",
  },
];

const supportList = [
  {
    text: "Google Play",
    link: "https://play.google.com/store/apps/details?id=com.ytc.pengenbisa",
  },
];

const Footer = () => {
  return (
    <div className="news-footer-wrap">
      <div className="fs-shape">
        <img src="assets/images/shape-img/03.png" alt="fst" className="fst-1" />
        <img src="assets/images/shape-img/04.png" alt="fst" className="fst-2" />
      </div>

      <footer className="pt-5">
        <div className="footer-top padding-tb pt-10">
          <div className="container">
            <div className="row g-4 row-cols-xl-4 row-cols-md-2 row-cols-1 justify-content-center">
              <div className="col">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h4>{siteTitle}</h4>
                      </div>
                      <div className="content">
                        <ul className="lab-ul">
                          {siteList.map((val, i) => (
                            <li key={i}>
                              <a
                                href={val.link}
                                target={"_blank"}
                                rel="noreferrer"
                              >
                                {val.text}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h4>{useTitle}</h4>
                      </div>
                      <div className="content">
                        <ul className="lab-ul">
                          {useList.map((val, i) => (
                            <li key={i}>
                              <a
                                href={val.link}
                                target={"_blank"}
                                rel="noreferrer"
                              >
                                {val.text}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h4>{socialTitle}</h4>
                      </div>
                      <div className="content">
                        <ul className="lab-ul">
                          {socialList.map((val, i) => (
                            <li key={i}>
                              <a
                                href={val.link}
                                target={"_blank"}
                                rel="noreferrer"
                              >
                                {val.text}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h4>{supportTitle}</h4>
                      </div>
                      <div className="content">
                        <ul className="lab-ul">
                          {supportList.map((val, i) => (
                            <li key={i}>
                              <a
                                href={val.link}
                                target={"_blank"}
                                rel="noreferrer"
                              >
                                <img
                                  src="assets/images/google-play.png"
                                  alt={val.text}
                                />
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom style-2">
          <div className="container">
            <div className="section-wrapper">
              <p>
                &copy; 2022 <Link to="/">Pengen Bisa</Link>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
