import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import About from "../component/section/about";
import Banner from "../component/section/banner";
import Course from "../component/section/course";
import Instructor from "../component/section/instructor";

const Home = () => {
  return (
    <Fragment>
      <Header />
      <Banner />
      <Course />
      <About />
      <Instructor />
      <Footer />
    </Fragment>
  );
};

export default Home;
