import { useState } from "react";
import { Link, NavLink } from "react-router-dom";

const phoneNumber = "+62 812-4646-2453";
const address =
  "Perumahan Grand Muslim 3 Blog DG 06, Labuapi, Lombok Barat, NTB";

let socialList = [
  {
    iconName: "icofont-facebook-messenger",
    siteLink: "https://web.facebook.com/Pengen-Bisa-109628460721763",
  },
  {
    iconName: "icofont-instagram",
    siteLink: "https://www.instagram.com/pengenbisa/",
  },
  {
    iconName: "icofont-email",
    siteLink:
      "https://mail.google.com/mail/?view=cm&fs=1&to=hallopengenbisa@gmail.com",
  },
];

const Header = () => {
  const [menuToggle, setMenuToggle] = useState(false);
  const [socialToggle, setSocialToggle] = useState(false);
  const [headerFiexd, setHeaderFiexd] = useState(false);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 200) {
      setHeaderFiexd(true);
    } else {
      setHeaderFiexd(false);
    }
  });

  return (
    <header
      className={`header-section ${headerFiexd ? "header-fixed fadeInUp" : ""}`}
    >
      <div className={`header-top ${socialToggle ? "open" : ""}`}>
        <div className="container">
          <div className="header-top-area">
            <ul className="lab-ul left">
              <li>
                <i className="icofont-whatsapp"></i> <span>{phoneNumber}</span>
              </li>
              <li>
                <i className="icofont-location-pin"></i> {address}
              </li>
            </ul>
            <ul className="lab-ul social-icons d-flex align-items-center">
              <li>
                <p>Find us on : </p>
              </li>
              {socialList.map((val, i) => (
                <li key={i}>
                  <a href={val.siteLink}>
                    <i className={val.iconName}></i>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="header-bottom">
        <div className="container">
          <div className="header-wrapper">
            <div className="logo">
              <Link to="/">
                <img src="assets/images/logo/01.png" alt="logo" />
              </Link>
            </div>
            <div className="menu-area">
              <div className="menu">
                <ul className={`lab-ul ${menuToggle ? "active" : ""}`}>
                <li>
                    <NavLink
                      to="#about"
                      onClick={() => {
                        document
                          .getElementById("about")
                          .scrollIntoView({ behavior: "smooth" });
                      }}
                      style={{color: '#105985'}}
                    >
                      About Us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="#course_list"
                      onClick={() => {
                        document
                          .getElementById("course_list")
                          .scrollIntoView({ behavior: "smooth" });
                      }}
                      style={{color: '#105985'}}
                    >
                      Courses
                    </NavLink>
                  </li>
                </ul>
              </div>

              {/* <Link to="/login" className="login">
                <i className="icofont-user"></i> <span>LOG IN</span>{" "}
              </Link>
              <Link to="/signup" className="signup">
                <i className="icofont-users"></i> <span>SIGN UP</span>{" "}
              </Link> */}

              <div
                className={`header-bar d-lg-none ${menuToggle ? "active" : ""}`}
                onClick={() => setMenuToggle(!menuToggle)}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div
                className="ellepsis-bar d-lg-none"
                onClick={() => setSocialToggle(!socialToggle)}
              >
                <i className="icofont-info-square"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
